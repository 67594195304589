<template>
  <div class="prompt-library">
    <div class="prompt-library__header">
      <i class="bx bx-plus box-icon box-icon__lg" title="Add a new Prompt" @click="toggleRequestAdded()"></i>
      <!-- <i :class="[mode === 'grid' ? 'bx bx-list-ul' : 'bx bxs-grid-alt']" class="box-icon toggle-mode-icon" @click="toggleKaaroWidgetView" title="Toggle Mode"></i> -->
    </div>
    <div class="prompt-library__playground mt-4">
      <!-- Playground Prompt -->
      <div class="form-group" v-if="isSmartGraphConnected">
        <label for="prompt-label">Enter the Prompt for testing the playground</label>
        <EdInput
          placeholder="What is heinsberg principle?"
          :value="playgroundPrompt"
          :onChange="setPlaygroundPrompt"
          Class="large-font"
        />
      </div>
      <!-- Playground Result -->
      <div class="prompt-library__playground-result" v-html="playgroundResponse"></div>
    </div>
    <form v-if="requestAdded" class="prompt-library__form" @submit.prevent="isPromptEdited ? updatePrompt() : createNewPrompt()">
      <div class="form-group">
        <label for="prompt-label">Enter the Prompt Label</label>
        <EdInput
          placeholder="Explain this as Charles Dickens"
          :value="label"
          :onChange="setPromptLabel"
          Class="large-font"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-visibility">Choose Visibility</label>
        <EdDropdown
          :listItems="availableVisibilites"
          :selected="selectedVisibility"
          :isSelected="setVisibility"
          styles="max-width: 8rem"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-description">Enter the Prompt Description</label>
        <!-- <EdInput
          placeholder="Enter description"
          :value="description"
          :onChange="setPromptDescription"
          Class="large-font"
        /> -->
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add description"
          class="mavonEditor"
          :showShortCut="false"
          v-model="description"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-type">Enter the Prompt Type</label>
        <EdInput
          placeholder="Enter type"
          :value="type"
          :onChange="setPromptType"
          Class="large-font"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-subtype">Enter the Prompt Subtype</label>
        <EdInput
          placeholder="Enter subtype"
          :value="subtype"
          :onChange="setPromptSubtype"
          Class="large-font"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-content">Enter the Prompt here</label>
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add your Prompt here..."
          class="mavonEditor"
          :showShortCut="false"
          v-model="prompt"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
          @save="savePrompt"
        />
      </div>
      <div class="form-group mt-4">
        <button class="ed-btn ed-btn__blim">
          <span v-if="isPromptEdited">Update Prompt</span>
          <span v-else>Create Prompt</span>
          <span class="spinner-border spinner-border-sm"
          :style="{ opacity: isLoading ? 1 : 0, marginLeft: '0.5rem' }"
          ></span>
        </button>
      </div>
    </form>
    <div class="prompt-library__prompts mt-4">
      <h4 class="prompt-library__heading">
        Available Prompts
      </h4>
      <hr/>
      <ul class="prompt-library__list-items" :class="{'grid-layout': mode === 'grid', 'list-layout': mode === 'list'}">
        <li class="prompt-library__list-item" v-for="prompt in prompts" :key="prompt.id" @click="openIntentWithPrompt(prompt)">
          <div class="prompt-library__list-item__body">
            {{ prompt.label }}
          </div>
          <div v-if="!isGameConnected" class="prompt-library__list-item__tools">
            <i v-if="isSmartGraphConnected" class="bx bx-play-circle box-icon" title="Kaaro Playground" @click="playKaaroOnSmartGraphMemory(prompt.id, isSmartGraphConnected)"></i>
            <i class="bx bx-copy box-icon" title="Copy Prompt Id" @click="copyToClipboard(prompt.id)"></i>
            <i class="bx bx-edit box-icon" title="Edit Prompt" @click="editPrompt(prompt)"></i>
            <i class="bx bx-trash box-icon" title="Delete Prompt" @click="deletePrompt(prompt.id)"></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EdInput from '@/common/components/ed-forms/ed-input.vue';
import EdDropdown from '@/common/components/ed-forms/ed-dropdown.vue';
import mavenEditorConfigs from '@/config/mavenEditor';

export default {
  name: 'promptLibrary',
  components: {
    EdInput,
    EdDropdown,
  },
  async mounted() {
    await this.fetchPrompts();
  },

  computed: {
    prompts() {
      return this.$store.getters['quests/allPrompts'];
    },
    isGameConnected() {
      return this.$store.state.game.connected;
    },
    isSmartGraphConnected() {
      if (this.graphDetailObject && this.graphDetailObject.memory_id) {
        return true;
      } return false;
      // return this.graphDetailObject.memory_id;
    },
    graphDetailObject() {
      return this.$store.state.gmodule.graph_detail;
    },
  },

  data() {
    return {
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
      label: '',
      prompt: '',
      description: '',
      type: '',
      subtype: '',
      selectedVisibility: 'public',
      availableVisibilites: ['public', 'private', 'custom'],
      isLoading: false,
      requestAdded: false,
      isPromptEdited: false,
      idOfPromptWhichIsCurrentlyEdited: '',
      mode: 'list',
      searchTerm: null,
      vectorStoreId: null,
      playgroundResponse: '',
      playgroundPrompt: '',
    };
  },
  methods: {
    copyToClipboard(content) {
      navigator.clipboard.writeText(content);
    },
    async playKaaroOnSmartGraphMemory(kaaroId) {
      // console.log('xvf', 'Kaaro Id', kaaroId, smartGraphId, this.graphDetailObject, this.$store.state.eddie.mainAssistantId);
      this.playgroundResponse = '';
      await this.$store.dispatch('quests/promptPlayground', {
        vectorStoreId: this.graphDetailObject.memory_id.vector_store_id,
        intentId: kaaroId,
        prompt: this.plagroundPrompt,
        assistantId: this.$store.state.eddie.mainAssistantId,
        callback: (chunk) => {
          this.playgroundResponse += chunk;
        },
      });
      // console.log('xvf', 'response', msg);
    },
    async fetchPrompts() {
      await this.$store.dispatch('quests/fetchAllPrompts');
    },
    setPromptLabel(value) {
      this.label = value;
    },
    setPlaygroundPrompt(value) {
      this.plagroundPrompt = value;
    },
    setVisibility(value) {
      this.selectedVisibility = value;
    },
    setPromptDescription(value) {
      this.description = value;
    },
    setPromptType(value) {
      this.type = value;
    },
    setPromptSubtype(value) {
      this.subtype = value;
    },
    async createNewPrompt() {
      this.isLoading = true;
      await this.$store.dispatch('quests/createPrompt', {
        label: this.label,
        prompt: this.prompt,
        description: this.description,
        type: this.type,
        subtype: this.subtype,
        visibility: this.selectedVisibility,
      });
      this.isLoading = false;
      this.label = '';
      this.prompt = '';
      this.description = '';
      this.type = '';
      this.subtype = '';
    },
    async updatePrompt() {
      this.isLoading = true;
      await this.$store.dispatch('quests/updatePrompt', {
        label: this.label,
        id: this.idOfPromptWhichIsCurrentlyEdited,
        visibility: this.selectedVisibility,
        prompt: this.prompt,
        description: this.description,
        type: this.type,
        subtype: this.subtype,
      });
      this.isLoading = false;
      this.label = '';
      this.prompt = '';
      this.description = '';
      this.type = '';
      this.subtype = '';
      this.idOfPromptWhichIsCurrentlyEdited = '';
      this.isPromptEdited = false;
    },
    async editPrompt(prompt) {
      this.isPromptEdited = true;
      this.label = prompt.label;
      this.prompt = prompt.prompt;
      this.description = prompt.description;
      this.type = prompt.type;
      this.subtype = prompt.subtype;
      this.idOfPromptWhichIsCurrentlyEdited = prompt.id;
      this.selectedVisibility = prompt.visibility;
      this.requestAdded = true;
    },
    async deletePrompt(id) {
      this.$store.dispatch('quests/deletePrompt', { id });
    },
    toggleRequestAdded() {
      if (this.requestAdded) {
        this.isPromptEdited = false;
        this.label = '';
        this.prompt = '';
        this.description = '';
        this.type = '';
        this.subtype = '';
      }
      this.requestAdded = !this.requestAdded;
    },
    toggleKaaroWidgetView() {
      // this.mode = this.mode === 'list' ? 'grid' : 'list';
      this.mode = 'grid';
    },
    setCurrentThought(searchTerm, vectorStoreId = null) {
      this.searchTerm = searchTerm;
      this.vectorStoreId = vectorStoreId;
    },
    openIntentWithPrompt(prompt) {
      if (this.mode === 'grid') {
        console.log('xvf', 'prompt', prompt, this.searchTerm);
        // this.$router.push({ name: 'IntentLoader', params: { intentId: prompt.id, searchTerm: this.searchTerm } });
        this.$router.push({ name: 'WidgetView', params: { name: 'IntentLoaderWidget' }, query: { setCurrentThought: `[${this.searchTerm},${prompt.id},${this.vectorStoreId}]` } });
      }
    },
  },
};
</script>

<style lang="scss">
.large-font {
  font-size: 14px;
  line-height: 1.6;
  max-width: unset;
}
.prompt-library {
  &__header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  &__list-items {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.grid-layout {
      display: flex;
      flex-wrap: wrap;
      li {
        // flex: 0 0 3rem;
        width: 18rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-grey-3;
        margin: 0.5rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        background: rgba(0, 0, 0, 0.1);

        &:hover {
          background: rgba(101, 157, 189, 0.1);
        }
      }
    }

    &.list-layout {
      li {
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-grey-3;
      }
    }
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__body {
      flex: 1;
    }

    &__tools {
      display: flex;
      gap: 0.5rem;
    }
  }

  .toggle-mode-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
}
</style>
